import { Component } from '@angular/core';
import {AsyncPipe, CommonModule, NgClass, NgForOf} from "@angular/common";
import {OnboardingService} from "../core/services/onboarding/onboarding.service";
import {GetStepsResponse, StepModel} from "../core/services/onboarding/get-steps.response";
import {NGXLogger} from "ngx-logger";
import { NgIf } from "@angular/common";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthenticateService } from '../core/services/auth/authenticate.service';

@Component({
  selector: 'app-onboarding',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    AsyncPipe,
    NgClass, 
    CommonModule
  ],
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.css'
})
export class OnboardingComponent {
  currentStep = 0;
  steps: GetStepsResponse[] = [];
  downloads: string[] = [];
  copies: string[] = [];

  constructor(
    private onboardingService: OnboardingService,
    private logger: NGXLogger,
    private sanitizer: DomSanitizer,
    private authService: AuthenticateService,
  ) {
    this.onboardingService.getStepsForUser().subscribe({
        next: response => {
          this.steps = response
          this.steps.forEach((step, i) => {
            this.pushDownload(i);
            if (step.isCompleted){
              this.currentStep++;
            }
          });
        },
        error: err => {
          console.error(err);
        }
      });
  }

  getCurrentStep(): StepModel {
    if (this.currentStep >= this.steps.length){
      this.currentStep = this.steps.length -1;
    }
    return this.steps[this.currentStep].step
  } 

  getId(): string {
    return this.getCurrentStep().id;
  }

  getContent(): SafeHtml {
    let introduction = this.getCurrentStep().introduction;
    introduction = this.styleVideo(introduction);
    return this.sanitizer.bypassSecurityTrustHtml(introduction);
  }

  getWindowsContent(): SafeHtml {
    let winContent = this.getCurrentStep().winContent;
    winContent = this.styleCopy(winContent)
    return this.sanitizer.bypassSecurityTrustHtml(winContent);
  }

  getMacOSContent(): SafeHtml {

    let osxContent = this.getCurrentStep().osxContent;
    osxContent = this.styleCopy(osxContent)
    return osxContent;
  }

  getLinuxContent(): SafeHtml {
    let linuxContent = this.getCurrentStep().linuxContent;
    linuxContent = this.styleCopy(linuxContent)
    return linuxContent;
  }

  nextStep(): void {
    this.onboardingService.StepCompleted(this.getId()).subscribe({
      next: () => {
        console.log("next step")
      },
      error: err => {
        console.error("error getting step:", err)
      }
    });

    this.logger.info(`${this.currentStep} of ${this.steps.length}`)
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++
    }
    this.logger.info(this.currentStep)
  }

  previousStep() {
    this.logger.info('previous step')
    if (this.currentStep > 0) {
      this.currentStep--
    }
    this.logger.info(this.currentStep)
  }

  styleVideo(htmlString: string) : string {
    const iframeRegex = new RegExp('<iframe.*?<\/iframe>', 'gi');

    if (iframeRegex.test(htmlString)) {
      htmlString = htmlString.replace("<iframe", "<iframe style=\"border: 0; width: 600px; height: 350px; overflow: auto;\"");
      return htmlString.replace(iframeRegex, (match) => {
        return `<div style="display: flex; height: 350px; justify-content: center; margin: 0 auto;">${match}<\/div>`;
      });
    }
    return htmlString;
  }

  styleCopy(htmlString: string) : string {
    if (htmlString.includes("copyUserId")) {
      const userId = this.authService.getUserId();
      console.log("does contain copyUserId", userId)
      htmlString = htmlString.replace("copyUserId", `<div class="grid grid-cols-8 gap-2 w-full max-w-[23rem]" style="display: grid; grid-template-columns: repeat(8, 1fr); gap: 0.5rem; width: 105%; max-width: 23rem;">
                <label for="npm-install" class="sr-only" style="position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0,0,0,0); border: 0;">Label<\/label>
                
                <input id="npm-install" type="text" value="${userId}" disabled readonly 
                    style="grid-column: span 6; background-color: #f9fafb; border: 1px solid #d1d5db; color: #6b7280; font-size: 0.875rem; border-radius: 0.5rem; width: 105%; padding: 0.625rem; 
                    focus-within:ring: #3b82f6; focus-within:border-color: #3b82f6; background-color: #374151; border-color: #4b5563; color: #9ca3af;" />
                
            </div>`)
      return htmlString;
    }

    return htmlString;
  }

  pushDownload(i: number) {
    const regex = new RegExp("\\b[\\w-]+\\.exe\\b", "i");
    const step = this.steps[i].step;
    
    const match = regex.exec(step.winContent);
    if (match) {
      const download = "../../assets/downloads/" + match[0];
      this.downloads.push(download);
      step.winContent = step.winContent.replace(match[0], "")
    } else {
      this.downloads.push("")
    }
  }
}

