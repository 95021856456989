import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ENV} from "../../../../environments/environment.provider";
import {Environment} from "../../../../environments/ienvironment";
import {Observable} from "rxjs";
import {GetStepsResponse} from "./get-steps.response";
import { AuthenticateService } from "../auth/authenticate.service";
import { StepCompletedRequest } from "./step-completed-request";

@Injectable({
  providedIn: 'root'
})
export class OnboardingService{

  constructor(
    private http: HttpClient,
    @Inject(ENV) private env: Environment,
    private authService: AuthenticateService
  ) {
  }

  getStepsForUser(): Observable<GetStepsResponse[]> {
    const userId = this.authService.getUserId();
    return this.http.get<GetStepsResponse[]>(`${this.env.sikrApiUrl}/api/v1/onboarding/${userId}/steps`);
  }

  StepCompleted(stepId: string): Observable<void> {
    const stepCompletedRequest: StepCompletedRequest = { stepId: stepId };
    const userId = this.authService.getUserId();
    return this.http.post<void>(`${this.env.sikrApiUrl}/api/v1/onboarding/${userId}/step/complete`, stepCompletedRequest);
  }
}
