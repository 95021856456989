import {Inject, Injectable} from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {BehaviorSubject, catchError, filter, finalize, map, Observable, switchMap, take} from "rxjs";
import {ENV} from "../../../environments/environment.provider";
import {Environment} from "../../../environments/ienvironment";
import {NGXLogger} from "ngx-logger";
import {NotifyService} from "../services/notify/notify.service";
import {AuthenticateService} from "../services/auth/authenticate.service";
import {StorageService} from "../services/storage/storage.service";
import {Router} from "@angular/router";
import {AuthenticateResponse} from "../services/auth/authenticate.response";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isUpdatingRefreshToken = false;
  private refreshTokenSubject: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(
    null
  );


  constructor(
    private authService: AuthenticateService,
    private storageService: StorageService,
    private notifyService: NotifyService,
    private logger: NGXLogger,
    private router: Router,
    @Inject(ENV) private env: Environment,

  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes('/auth/')) {
      return next.handle(req);
    } else {
      const accessToken = this.storageService.getAccessToken();
      const modifiedRequest = this.addTokenToRequest(req, accessToken);

      return next.handle(modifiedRequest).pipe(
        catchError((error) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                return this.handle401(req, next);
              }
            }
            throw error
          }
        ));
    }
  }

  private handle401(request: HttpRequest<unknown>, next: HttpHandler) {
    this.logger.debug('refreshing access token', request.url)
    if (!this.isUpdatingRefreshToken) {
      this.logger.debug('making request', request.url)
      this.isUpdatingRefreshToken = true;
      // wait until the token comes back from the refreshToken call (thus reset).
      this.refreshTokenSubject.next(null);

      return this.authService.refreshTokens().pipe(
        switchMap((response: AuthenticateResponse) => {
          if(response.accessToken && response.refreshToken) {
            this.logger.debug('refreshed access and refresh token')
            this.isUpdatingRefreshToken = false;
            this.refreshTokenSubject.next(response.accessToken);
            return next.handle(this.addTokenToRequest(request, response.accessToken));
          } else { // Something is wrong with the response
            this.logger.error(`invalid access(${response.accessToken}) or refresh(${response.refreshToken}) token`)
            this.logout()
            throw new Error('failed to refresh access token')
          }
        }), catchError((error) => {
          this.logger.error(error)
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this.logger.info('refresh token has expired')
              this.notifyService.displayError('Sessionen er udløbet')
              this.logout()
            }
          }
          throw error
        }),
        finalize(() => {
          this.isUpdatingRefreshToken = false;
        }));
    } else {
      this.logger.debug('waiting for new access token')
      return this.refreshTokenSubject.pipe(
        filter((tokens) => (tokens != null)),
        take(1),
        map((tokens => typeof tokens === 'string' ? tokens : '')), // cast unknown to string
        switchMap((accessToken) => next.handle(this.addTokenToRequest(request, accessToken)))
      );
    }
  }


  private addTokenToRequest(request: HttpRequest<unknown>, accessToken: string) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + accessToken),
    });
  }

  private logout() {
    this.authService.logout()
    this.router.navigate(['auth/login'])
  }

}
