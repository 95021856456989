import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ENV} from "../../../../environments/environment.provider";
import {Environment} from "../../../../environments/ienvironment";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {AuthenticateRequest} from "./authenticate.request";
import {StorageService} from "../storage/storage.service";
import {RefreshAccessTokenResponse} from "./refresh-access-token.response";
import {RefreshAccessTokenRequest} from "./refresh-access-token.request";
import {AuthenticateResponse} from "./authenticate.response";
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    @Inject(ENV) private env: Environment,
  ) { }

  authenticateUser(authenticateRequest: AuthenticateRequest): Observable<AuthenticateResponse> {
    return this.http.post<AuthenticateResponse>(`${this.env.sikrApiUrl}/api/v1/auth/authenticate`, authenticateRequest)
      .pipe(
        tap((response) => {
          this.storageService.setAccessToken(response.accessToken)
          this.storageService.setRefreshToken(response.refreshToken)
        })
      );
  }

  refreshTokens(): Observable<RefreshAccessTokenResponse> {
    const refreshToken = this.storageService.getRefreshToken()
    const request: RefreshAccessTokenRequest = {
      refreshToken: refreshToken
    }
    return this.http.post<RefreshAccessTokenResponse>(`${this.env.sikrApiUrl}/api/v1/auth/refresh`, request)
      .pipe(
        tap(response => {
          this.storageService.setAccessToken(response.accessToken)
          this.storageService.setRefreshToken(response.refreshToken)
        })
      )
  }

  getUserId(): string {
    if (!this.isLoggedIn()){
      console.error("tried to get user id when not logged in");
      return "";
    }

    const accessToken = this.storageService.getAccessToken();
    const decoded = JSON.stringify(jwtDecode(JSON.stringify(accessToken)));

    const userIdLocalKey = "dk.mapgroup.userclaim";
    const userIdProdKey = "io.sikr.userclaim";
    const userid = JSON.parse(decoded)[userIdProdKey];

    if (userid){
      return userid
    }
    return JSON.parse(decoded)[userIdLocalKey];
  }

  isLoggedIn(): boolean {
    return this.storageService.getAccessToken().length > 0
  }

  logout() {
    this.storageService.clearUserValues()
  }

}
