import {Inject, Injectable} from "@angular/core";
import {NGXLogger} from "ngx-logger";
import {ENV} from "../../../../environments/environment.provider";
import {Environment} from "../../../../environments/ienvironment";

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(
    @Inject(ENV) private env: Environment,
    private logger: NGXLogger,
  ) {
  }

  displayError(message: string) {
    this.logger.debug(`should show error: ${message}`)
  }

  displaySuccess(message: string) {
    this.logger.debug(`should show success: ${message}`)
  }

  displayInfo(message: string) {
    this.logger.debug(`should show info: ${message}`)
  }

  displayWarning(message: string) {
    this.logger.debug(`should show warning: ${message}`)
  }

}
