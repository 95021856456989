import {Inject, Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class StorageService {

  private readonly accessTokenKey = "sikr_access_token"
  private readonly refreshTokenKey = "sikr_refresh_token"

  constructor(
    @Inject('LOCALSTORAGE') private localStorage: Storage,
  ){}

  getAccessToken(): string {
    const value = this.localStorage.getItem(this.accessTokenKey)
    return this.handleStringNull(value)
  }
  setAccessToken(accessToken: string) {
    this.localStorage.setItem(this.accessTokenKey, accessToken)
  }

  getRefreshToken(): string {
    const value = this.localStorage.getItem(this.refreshTokenKey)
    return this.handleStringNull(value)
  }
  setRefreshToken(refreshToken: string) {
    this.localStorage.setItem(this.refreshTokenKey, refreshToken)
  }

  clearUserValues() {
    this.localStorage.removeItem(this.accessTokenKey)
    this.localStorage.removeItem(this.refreshTokenKey)
  }

  private handleStringNull(s: string | null): string {
    if (s === null) {
      return ''
    } else {
      return s
    }
  }
}


