import { NgxLoggerLevel } from 'ngx-logger';
import {Environment} from './ienvironment';


export const environment: Environment = {
  production: true,
  name: 'prod',
  logLevel: NgxLoggerLevel.INFO,
  sikrApiUrl: 'https://api.sikr.io',
};
