import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LayoutComponent} from './layout/layout.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ENV, getEnv} from "../environments/environment.provider";
import {LoggerModule} from "ngx-logger";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {AuthInterceptor} from "./core/interceptors/auth.interceptor";
import {ResizeObserverModule} from "@ng-web-apis/resize-observer";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {OnboardingComponent} from "./onboarding/onboarding.component";
import {LoginComponent} from "./login/login.component";
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MarkdownModule} from "ngx-markdown";
import { AuthGuard } from './core/guards/auth.guard';

@NgModule({ declarations: [
    AppComponent,
    LayoutComponent,
    NotFoundComponent,
  ],
  exports: [],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoggerModule.forRoot({
      level: getEnv().logLevel,
    }),
    MarkdownModule.forRoot(),
    ResizeObserverModule,
    OnboardingComponent,
    LoginComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ENV, useFactory: getEnv },
    { provide: 'LOCALSTORAGE', useValue: window.localStorage },
    provideHttpClient(withInterceptorsFromDi()),
    AuthGuard
  ] })
export class AppModule { }
